@tailwind base;
@tailwind components;
@tailwind utilities;

.privacy-policy {
    white-space: pre-wrap;
}
.privacy-policy ul {
    list-style: normal;
    padding-left: 20px;
    white-space: normal;
}